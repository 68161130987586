import React, { useState } from 'react';
import apiService from '../../services/apiService';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState('');

    const token = localStorage.getItem('token');

    const handleGetAllUsers = async () => {
        try {
            const response = await apiService.get('/api/users');
            setUsers(response.data);
        } catch (error) {
            console.error('Failed to fetch users', error);
        }
    };

    const handleGetUser = async () => {
        try {
            const response = await apiService.get(`/api/users/${userId}`);
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch user', error);
        }
    };

    return (
        <div className="flex flex-col items-center h-screen bg-gray-100 p-6">
            <h2 className="text-2xl font-bold mb-4">User Management</h2>
            <div className="mb-4">
                <button
                    onClick={handleGetAllUsers}
                    className="py-2 px-4 bg-blue-500 text-white rounded mr-2"
                >
                    Get All Users
                </button>
                <input
                    type="text"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    placeholder="User ID"
                    className="py-2 px-3 border border-gray-300 rounded mr-2"
                />
                <button
                    onClick={handleGetUser}
                    className="py-2 px-4 bg-green-500 text-white rounded"
                >
                    Get User
                </button>
            </div>
            <div className="w-full max-w-3xl">
                {users.length > 0 && (
                    <div>
                        <h3 className="text-xl font-semibold mb-2">All Users</h3>
                        <ul className="list-disc pl-5">
                            {users.map(user => (
                                <li key={user.id}>
                                    {user.username} - {user.email}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {user && (
                    <div className="mt-4">
                        <h3 className="text-xl font-semibold mb-2">User Details</h3>
                        <p>ID: {user.id}</p>
                        <p>Username: {user.username}</p>
                        <p>First Name: {user.firstName}</p>
                        <p>Last Name: {user.lastName}</p>
                        <p>Email: {user.email}</p>
                        <p>Active: {user.isActive ? 'Yes' : 'No'}</p>
                        <p>Admin: {user.isAdmin ? 'Yes' : 'No'}</p>
                        <p>Delete: {user.isDelete ? 'Yes' : 'No'}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserManagement;
