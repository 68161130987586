import React, { useState, useEffect } from "react";
import "tailwindcss/tailwind.css";
import { PencilAltIcon } from "@heroicons/react/solid";
import UpdateStore from "./UpdateStore";
import apiService from "../../services/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateStore from "./CreateStore";

const StoreComponent = () => {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [isStoreCredentialsModalOpen, setIsStoreCredentialsModalOpen] =
    useState(false);
  //   const [storeStatuses, setStoreStatuses] = useState([]);

  useEffect(() => {
    // let storeStatus = [];

    const fetchStores = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No authentication token found");
        }

        const res = await apiService.get("/api/store-status");
        // console.log(res.data);
        // setStoreStatuses(res.data);

        const response = await apiService.get("/api/stores");
        const responseWithStatus = response.data.map((store) => {
          return {
            ...store,
            storeStatusName:
              res.data.find((status) => status.id === store.fkStoreStatus)
                ?.statusName || "Unknown",
          };
        });
        console.log(responseWithStatus);
        // console.log(storeStatuses);

        setStores(responseWithStatus);
        // setStores(response.data);
        // toast.success("Stores loaded successfully!");
      } catch (error) {
        console.error("Failed to fetch stores", error);
        setError("Failed to fetch stores");
        toast.error("Failed to fetch stores");
      } finally {
        setLoading(false);
      }
    };

    // getStoreStatus();
    fetchStores();
  }, []);

  const handleStoreOpenModal = (store) => {
    console.log(store);
    setSelectedStore(store);
    setIsModalOpen(true);
  };

  const handleStoreCloseModal = () => {
    setIsModalOpen(false);
    setSelectedStore(null);
  };

  const handleAddStoreOpenModal = () => {
    setIsStoreCredentialsModalOpen(true);
  };

  const handleAddStoreCloseModal = () => {
    setIsStoreCredentialsModalOpen(false);
  };
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="bg-white p-6 rounded shadow-md relative">
      <ToastContainer />
      <div className="flex justify-between">
        <div className="mb-4 flex-3/4">
          <h2 className="text-xl font-bold mb-4" style={{ color: "#225282" }}>Stores</h2>
        </div>
        <div className="mb-4 flex-1/4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleAddStoreOpenModal}
          >
            Add Store
          </button>
        </div>
      </div>
      <div className="overflow-x-auto text-sm">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b text-left">ID</th>
              <th className="py-2 px-4 border-b text-left">Store Name</th>
              <th className="py-2 px-4 border-b text-left">Current ACM ID</th>
              <th className="py-2 px-4 border-b text-left">Cloudfront ID</th>
              <th className="py-2 px-4 border-b text-left">Store Status</th>
              <th className="py-2 px-4 border-b text-left">Update</th>
            </tr>
          </thead>
          <tbody>
            {stores.map((store) => (
              <tr key={store.id}>
                <td className="py-2 px-4 border-b">{store.ID}</td>
                <td className="py-2 px-4 border-b">{store.storeName}</td>
                <td className="py-2 px-4 border-b">{store.currentAcmID}</td>
                <td className="py-2 px-4 border-b">{store.storeCfID}</td>
                <td className="py-2 px-4 border-b">{store.storeStatusName}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <button
                    onClick={() => handleStoreOpenModal(store)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    <PencilAltIcon className="h-5 w-5 inline" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <UpdateStore onClose={handleStoreCloseModal} store={selectedStore} />
      )}
      {isStoreCredentialsModalOpen && (
        <CreateStore onClose={handleAddStoreCloseModal} />
      )}
    </div>
  );
};

export default StoreComponent;
