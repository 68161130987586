import React, { useState } from "react";
import closeIcon from "../../assest/img/close.png";
import apiService from "../../services/apiService";

const DnsPopup = ({ data, onClose, agencyId, token }) => {
  const [dnsStatus, setDnsStatus] = useState(null); 

  const validateDNS = async () => {
    try {
      const response = await apiService.get(`/api/certificates/CheckDNSPropagationStatus/${agencyId}`);

      const result = await response.json();
      if (response.ok) {
        setDnsStatus(result.isStoreDnsPropagated && result.isTLSDnsPropagated ? 'success' : 'pending');
      } else {
        // Handle error
        console.error("Failed to validate DNS");
        setDnsStatus('pending');
      }
    } catch (error) {
      console.error("Error:", error);
      setDnsStatus('pending');
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-lg relative max-w-4xl w-full max-h-96 overflow-auto">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-900 focus:outline-none"
        >
          <img src={closeIcon} alt="Close" className="h-4 w-4" />
        </button>
        <h2 className="text-xl font-bold mb-4">DNS Details</h2>
        <div className="mb-4">
          <table className="w-full border-collapse table-fixed">
            <thead>
              <tr>
                <th className="border px-4 py-2 w-1/4">Type</th>
                <th className="border px-4 py-2 w-1/4">Hostname</th>
                <th className="border px-4 py-2 w-1/4">Value</th>
                <th className="border px-4 py-2 w-1/4">Status</th>
              </tr>
            </thead>
            <tbody>
               {data.dnsRecords.map((record, index) => ( 
                <React.Fragment 
                key={index}
                >
                  <tr>
                    <td className="border px-4 py-2">{record.type}CNAME</td>
                    <td className="border px-4 py-2">{record.certDNSName}</td>
                    <td className="border px-4 py-2">{record.certDNSValue}</td>
                    <td className="border px-4 py-2">
                      <span className={`bg-${dnsStatus === 'success' ? 'green' : 'yellow'}-200 text-${dnsStatus === 'success' ? 'green' : 'yellow'}-800 font-bold p-1 rounded`}>
                        {dnsStatus === 'success' ? 'Success' : 'Validation Pending...'}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">{record.type}CNAME</td>
                    <td className="border px-4 py-2">{record.agencyName}</td>
                    <td className="border px-4 py-2">{record.storeName}</td>
                    <td className="border px-4 py-2">
                      <span className={`bg-${dnsStatus === 'success' ? 'green' : 'yellow'}-200 text-${dnsStatus === 'success' ? 'green' : 'yellow'}-800 font-bold p-1 rounded`}>
                        {dnsStatus === 'success' ? 'Success' : 'Validation Pending...'}
                      </span>
                    </td>
                  </tr>
                </React.Fragment>
              ))} 
            </tbody>
          </table>
        </div>
        <div className="flex justify-end space-x-4 mt-4">
          <button
            onClick={validateDNS}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
          >
            Validate DNS
          </button>
          <button
            onClick={() => alert("Submit clicked")}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DnsPopup;
