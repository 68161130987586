import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "tailwindcss/tailwind.css";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// import { PencilAltIcon, TrashIcon, PlusCircleIcon } from "@heroicons/react/solid";
// import ConfirmationModal from "./ConfirmationModal";
// import AddCertificateModal from "../certificates/AddCertificateModal";
import { jwtDecode } from "jwt-decode";
// import Modal from "../../Modal1";
// import { useLocation } from "react-router-dom";
import apiService from "../../services/apiService";
import Loader from "../loader/Loader";

const AddAgency = ({ setActiveTab, onClose }) => {
    const [agencyName, setAgencyName] = useState("");
    const [agencyDomainName, setAgencyDomainName] = useState("");
    const [fkAssignedStore, setFkAssignedStore] = useState(0);
    const [fkAgencyStatus, setFkAgencyStatus] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const [isDelete, setIsDelete] = useState(false);
    const [createdBy, setCreatedBy] = useState(0);
    const [updatedBy, setUpdatedBy] = useState(0);
    const [agencyStatuses, setAgencyStatuses] = useState([]);
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setCreatedBy(decodedToken.userId);
            setUpdatedBy(decodedToken.userId);
        }

        const fetchAgencyStatuses = async () => {
            try {
                if (!token) {
                    console.error("No authentication token found");
                    toast.error("No authentication token found",{autoClose:3000});
                    return;
                }

                const response = await apiService.get("/api/agency-status");

                setAgencyStatuses(response.data);
            } catch (error) {
                console.error("Failed to fetch agency statuses", error);
                toast.error("Failed to fetch agency statuses",{autoClose:3000});
            }   
        };

        const fetchStores = async () => {
            try {
                if (!token) {
                    console.error("No authentication token found");
                    toast.error("No authentication token found",{autoClose:3000});
                    return;
                }

                const response = await apiService.get("/api/stores");
                setStores(response.data);
            } catch (error) {
                console.error("Failed to fetch stores", error);
                toast.error("Failed to fetch stores",{autoClose:3000});
            }
        };

        fetchAgencyStatuses();
        fetchStores();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No authentication token found");
                toast.error("No authentication token found",{autoClose:3000});
                setLoading(false);
                return;
            }

            const response = await apiService.post(
                "/api/agencies/",
                {
                    agencyName,
                    agencyDomainName,
                    fkAssignedStore: fkAssignedStore || null,
                    fkAgencyStatus: fkAgencyStatus || null,
                    isActive,
                    isDelete,
                    createdBy,
                    updatedBy,
                },
            );
            toast.success("Agency created successfully!",{autoClose:3000});
            onClose();
            window.location.reload();
            setActiveTab("list");
        } catch (error) {
            console.error("Failed to create agency", error);
            toast.error("Failed to create agency",{autoClose:3000});
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
            <ToastContainer/>
            {loading && <Loader/>}
            <form
                onSubmit={handleSubmit}
                className="bg-white p-6 rounded shadow-md w-96"
            >
                <h2 className="text-2xl font-bold mb-4">Create Agency</h2>
                <div className="mb-4">
                    <label
                        htmlFor="agencyName"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Agency Name
                    </label>
                    <input
                        type="text"
                        id="agencyName"
                        value={agencyName}
                        onChange={(e) => setAgencyName(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label
                        htmlFor="agencyDomainName"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Agency Domain Name
                    </label>
                    <input
                        type="text"
                        id="agencyDomainName"
                        value={agencyDomainName}
                        onChange={(e) => setAgencyDomainName(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label
                        htmlFor="fkAssignedStore"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Assigned Store
                    </label>
                    <select
                        id="fkAssignedStore"
                        value={fkAssignedStore}
                        onChange={(e) => setFkAssignedStore(parseInt(e.target.value))}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
                        required
                    >
                        <option value="">Select Store</option>
                        {stores.map((store) => (
                            <option key={store.ID} value={store.ID}>
                                {store.storeName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label
                        htmlFor="fkAgencyStatus"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Agency Status
                    </label>
                    <select
                        id="fkAgencyStatus"
                        value={fkAgencyStatus}
                        onChange={(e) => setFkAgencyStatus(parseInt(e.target.value))}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
                        required
                    >
                        <option value="">Select Status</option>
                        {agencyStatuses.map((status) => (
                            <option key={status.id} value={status.id}>
                                {status.statusName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label
                        htmlFor="isActive"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Is Active
                    </label>
                    <input
                        type="checkbox"
                        id="isActive"
                        checked={isActive}
                        onChange={(e) => setIsActive(e.target.checked)}
                        className="mt-1"
                    />
                </div>
                <div className="mb-4">
                    <label
                        htmlFor="isDelete"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Is Delete
                    </label>
                    <input
                        type="checkbox"
                        id="isDelete"
                        checked={isDelete}
                        onChange={(e) => setIsDelete(e.target.checked)}
                        className="mt-1"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600"
                >
                    Create
                </button>
                <button
                    type="button"
                    onClick={onClose}
                    className="w-full py-2 px-4 bg-gray-500 text-white rounded mt-2 hover:bg-red-500"
                >
                    Close
                </button>
            </form>
        </div>
    );
};

export default AddAgency;