import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../../services/apiService";
import { jwtDecode } from "jwt-decode";
import "../userprofile/UserProfile.css";

function UserProfile() {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = jwtDecode(token);
      console.log("This parse token details : ", decodedToken);
      const userId = decodedToken.id;

      apiService
        .get(`/api/users/${userId}`)
        .then((response) => {
          setUserData(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    } else {
      // Handle case where there is no token
      console.error("No token found in local storage");
    }
  }, []);

  if (!userData) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  const maskedMfaSecretKey = userData.mfaSecretKey
    ? "x".repeat(userData.mfaSecretKey.length - 4) +
      userData.mfaSecretKey.slice(-4)
    : "";

  return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-100 to-purple-100">
      <div className="bg-white shadow-2xl rounded-lg p-8 w-full max-w-lg animate-flash">
        <h2 className="text-4xl font-extrabold text-center mb-8" style={{ color: "#225282" }}>
          User Profile
        </h2>
        <div className="space-y-6">
          {[
            { label: "Username", value: userData.username },
            { label: "First Name", value: userData.firstName },
            { label: "Last Name", value: userData.lastName },
            { label: "Email", value: userData.email },
            {
              label: "2FA Status",
              value: userData.isMfaEnabled
                ? "2FA is Enabled"
                : "2FA is required",
              valueClass: userData.isMfaEnabled
                ? "text-green-600"
                : "text-red-600",
            },
            {
              label: "MFA Secret Key",
              value: maskedMfaSecretKey,
              valueClass: userData.isMfaEnabled
                ? "text-gray-800"
                : "text-red-600",
            },
          ].map(({ label, value, valueClass }, index) => (
            <div key={index} className="flex justify-between items-center">
              <span className="font-semibold text-gray-700">{label}:</span>
              <span className={`text-lg ${valueClass || "text-gray-900"}`}>
                {value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
