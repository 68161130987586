import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QRCodeGenerator = () => {
const location = useLocation();
  const [totpData, setTotpData] = useState(null);
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const { username } = location.state || {};

  useEffect(() => {
    // Call the API to generate TOTP when the component is mounted
    const generateTotp = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL+"genrate-totp"}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setTotpData(response.data.data);
      } catch (error) {
        console.error('Error generating TOTP:', error);
      }
    };

    generateTotp();
  }, []);

  const handleOtpChange = (e) => {
    setCode(e.target.value);
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL+"verify-totp"}`,
        { code, UserName: username },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data.status) {
        localStorage.setItem('token',response.data.token);
        navigate('/admin-panel');
      } else {
        toast.error('Invalid OTP');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('Failed to verify OTP');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <ToastContainer />
      <div className="p-8 bg-white rounded shadow-lg text-center">
        {totpData ? (
          <>
            <h2 className="text-2xl font-bold mb-6">Scan the QR Code with your Authenticator App</h2>
            <div className="flex justify-center mb-6">
              <QRCode
                value={`otpauth://totp/${totpData.issuer}:${totpData.account}?secret=${totpData.key}&issuer=${totpData.issuer}&algorithm=${totpData.algorithm}&digits=${totpData.digits}&period=${totpData.period}`}
                size={256}
                includeMargin
              />
            </div>
            <div className="space-y-4">
              <input
                type="text"
                placeholder="Enter OTP"
                value={code}
                onChange={handleOtpChange}
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <button
                onClick={handleVerifyOtp}
                className="w-full bg-orange-500 text-white p-3 rounded hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                Verify OTP
              </button>
            </div>
          </>
        ) : (
          <p>Loading QR Code...</p>
        )}
      </div>
    </div>
  );
};

export default QRCodeGenerator;



