import React, { createContext, useState } from "react";

export const AgencyStatusContext = createContext();

export const AgencyStatusProvider = ({ children }) => {

    const [agencyStatus, setAgencyStatus] = useState(false);
    return (
        <AgencyStatusContext.Provider value={{ agencyStatus, setAgencyStatus }}>
            {children}
        </AgencyStatusContext.Provider>
    );
};