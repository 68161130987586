import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/users/Login";
import UserCredentials from "./components/users/UserCredentials";
import UserUpdate from "./components/users/UserUpdate";
import UserManagement from "./components/users/UserManagement";
import UserPanel from "./components/users/UserPanel";
import AdminDashboard from "./components/common/AdminDashboard";
import UpdateAgency from "./components/agencies/UpdateAgency";
import UpdateStore from "./components/stores/UpdateStore";
import UserList from "./components/users/UserList";
import DnsPopup from "./components/certificates/DnsPopup";
import Loader from "./components/loader/Loader";
import VerifyOTP from "./components/VerifyOtp";
import QRCodeGenerator from "./RegisterTOTP";
import {  AgencyStatusProvider } from "./contexts/agencyStatusContext";

function App() {
  return (
    <AgencyStatusProvider>

    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin-panel" element={<AdminDashboard />} />
        <Route path="/user-credentials" element={<UserCredentials />} />
        <Route path="/user-update" element={<UserUpdate />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/user-panel" element={<UserPanel />} />
        <Route path="/update-agency" element={<UpdateAgency />} />
        <Route path="/update-store" element={<UpdateStore />} />
        <Route path="/all-users" element={<UserList />} />
        <Route path="/dns-popup" element={<DnsPopup />} />
        <Route path="/loader" element={<Loader />} />
        <Route path="/verify-otp" element={<VerifyOTP />} />
        <Route path="/register-totp" element={<QRCodeGenerator />} />
      </Routes>
    </Router>
    </AgencyStatusProvider>
  );
}

export default App;
