import React, { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "tailwindcss/tailwind.css";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/solid";
import ConfirmationModal from "./ConfirmationModal";
import AddCertificateModal from "../certificates/AddCertificateModal";
import Modal from "../../Modal1";
import apiService from "../../services/apiService";
import AddAgency from "./addAgency";
import agencyService from "../../services/agencyService";
import { AgencyStatusContext } from "../../contexts/agencyStatusContext";
import Loader from "../loader/Loader";

const AgencyList = ({onUpdateAgency}) => {
  const [agencies, setAgencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const token = localStorage.getItem("token");
  const { agencyStatusResponse, setAgencyStatusResponse } =
    useContext(AgencyStatusContext);

  useEffect(() => {
    const fetchAgencies = async () => {
      try {
        const response = await agencyService.fetchAgencies();
        const agenciesData = response.data.data;
        setAgencies(agenciesData);
      } catch (error) {
        console.error("Failed to fetch agencies", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgencies();
  }, [token]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isAddCertificateModalOpen, setIsAddCertificateModalOpen] =
    useState(false);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [isAgencyCredentialsModalOpen, setIsAgencyCredentialsModalOpen] =
    useState(false);

  const handleOpenModal = (agency) => {
    setSelectedAgency(agency);
    setIsModalOpen(true);
  };
  const handleAddAgency = () => {
    setSelectedAgency(null);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAgency(null);
    // agencyService.fetchAgencies().then((response) => {
    //   setAgencies(response.data.data);
    // })
  };

  const handleOpenConfirmationModal = (agency) => {
    // alert(JSON.stringify(agency));
    setSelectedAgency(agency);
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setSelectedAgency(null);
  };

  const handleDeleteAgency = async () => {
    setIsLoaderVisible(true);
    setTimeout(async () => {
    try {
      await apiService
        .delete(`/api/agencies/${selectedAgency.ID}`)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Agency deleted successfully!");
            agencyService
              .fetchAgencies()
              .then((response) => {
                setAgencies(response.data.data);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
      // setAgencies(agencies.filter((agency) => agency.id !== selectedAgency.id));
    } catch (error) {
      console.error("Failed to delete agency", error);
    } finally {
      setIsLoaderVisible(false);
      setIsConfirmationModalOpen(false);
      setSelectedAgency(null);
    }
  }, 2000);
  };

  const handleOpenAddCertificateModal = (agency) => {
    if (agency) {
      if (agency.fkAgencyStatus > 2) {
        const AddCfResponse = apiService
          .post(`/api/certificates/AddDomainInCloudfront`, {
            agencyID: agency.ID,
            storeID: agency.fkAssignedStore,
          })
          .then((response) => {
            console.log(response.data);
            toast.success("Store Added Successfully");
            window.location.reload();
          })
          .catch((error) => {
            console.error(
              "Failed to add store! Please contact your Aws Administrator",
              error
            );
            toast.error(
              "Failed to add store! Please contact your Aws Administrator"
            );
          });
      } else {
        setSelectedAgency(agency);

        setIsAddCertificateModalOpen(true);
      }
    } else {
      console.error("No agency selected");
    }
  };

  const renderCertButton = (status) => {
    switch (status) {
      case 1:
        return "Request Certificate";
      case 2:
        return "View Certificate Request";
      case 3:
        return "Add To CloudFront";
      case 4:
        return "Activated";
      default:
        return "Request Certificate";
    }
  };
  const handleCloseAddCertificateModal = () => {
    setIsAddCertificateModalOpen(false);
    setSelectedAgency(null);
  };

  const handleAddAgencyOpenModal = () => {
    setIsAgencyCredentialsModalOpen(true);
  };

  const handleAddAgencyCloseModal = () => {
    setIsAgencyCredentialsModalOpen(false);
  };

  const onUpdateAgencyHandler = (updatedAgency) => {
    onUpdateAgency(updatedAgency);
    // Refresh the user list here if needed
    const fetchAgencies = async () => {
      try {
        const response = await apiService.get("/api/agencies/");
        if (Array.isArray(response.data.data)) {
          setAgencies(response.data.data);
        } else {
          setError("Unexpected response format");
        }
      } catch (error) {
        setError("Failed to fetch agencies");
        console.error("Failed to fetch agencies", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgencies();
  };


  if (loading) return <p>Loading...</p>;

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="overflow-x-auto">
      {isLoaderVisible && <Loader/>}
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <div className="flex-1">
          <h2 className="text-2xl font-bold" style={{ color: "#225282" }}>
            Agency List
          </h2>
        </div>
        {/* <div className="mt-4 md:mt-0">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full md:w-auto"
            onClick={handleAddAgencyOpenModal}
          >
            Add Agency
          </button>
        </div> */}
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Agency Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Domain Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Assigned Store
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Agency Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Active
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Update
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Delete
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Request Certificate
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {agencies.map((agency) => (
            <tr key={agency.ID}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {agency.agencyName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {agency.agencyDomainName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {agency.store.storeName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {agency.agencyStatus.statusName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {agency.isActive ? "Yes" : "No"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <button
                  onClick={() => handleOpenModal(agency)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <PencilAltIcon className="h-5 w-5 inline" />
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <button
                  onClick={() => handleOpenConfirmationModal(agency)}
                  className="text-red-500 hover:text-red-700"
                >
                  <TrashIcon className="h-5 w-5 inline" />
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <button
                  disabled={agency.fkAgencyStatus === 4}
                  onClick={() => handleOpenAddCertificateModal(agency)}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                >
                  {renderCertButton(agency.fkAgencyStatus)}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && selectedAgency &&(
        <Modal onClose={handleCloseModal} agency={selectedAgency} onUpdateAgency={onUpdateAgencyHandler}/>
      )}

      {isConfirmationModalOpen && (
        <ConfirmationModal
          onClose={handleCloseConfirmationModal}
          onConfirm={handleDeleteAgency}
          message={`Are you sure you want to delete the agency "${selectedAgency?.agencyName}"?`}
        />
      )}

      {isAddCertificateModalOpen && (
        <AddCertificateModal
          onClose={handleCloseAddCertificateModal}
          agency={selectedAgency}
        />
      )}
      {isAgencyCredentialsModalOpen && (
        <AddAgency onClose={handleAddAgencyCloseModal} />
      )}
      <ToastContainer />
    </div>
  );
};

export default AgencyList;
