import React from "react";
import { FaDragon, FaExclamationTriangle } from "react-icons/fa";

const ConfirmationModal = ({ onClose, onConfirm, message }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      

      <div className="bg-white p-6 rounded shadow-lg text-center">
      {/* <h2 className="text-xl font-bold mb-4">Confirm Action</h2> */}
      
        <div className="flex items-center justify-center mb-4">
        <FaExclamationTriangle className="text-6xl text-red-500" />
          
        </div>

      
      

        <p className="mb-4 text-gray-700 font-large">{message}</p>
        <hr className="my-4 border-t-2 border-gray-300" />
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
          >
            No
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white hover:bg-red-600 rounded"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
