import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import apiService from "../../services/apiService";

function UserPanel() {
  const [activeTab, setActiveTab] = useState("create");

  return (
    <div className="min-h-screen flex">
      {/* Left side navigation */}
      <div className="w-1/4 bg-purple-800 text-white p-4">
        <h2 className="text-2xl font-bold mb-4">User Panel</h2>
        <ul className="space-y-4">
          <li>
            <button
              onClick={() => setActiveTab("create")}
              className={`w-full text-left p-2 rounded ${
                activeTab === "create" ? "bg-purple-500" : ""
              }`}
            >
              Create
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("update")}
              className={`w-full text-left p-2 rounded ${
                activeTab === "update" ? "bg-purple-500" : ""
              }`}
            >
              Update
            </button>
          </li>
          {/* <li>
            <button
              onClick={() => setActiveTab("user management")}
              className={`w-full text-left p-2 rounded ${
                activeTab === "user management" ? "bg-purple-500" : ""
              }`}
            >
              User Management
            </button>
          </li> */}
        </ul>
      </div>

      {/* Right side content */}
      <div className="w-3/4 p-8">
        {activeTab === "create" && <UserCredentials />}
        {activeTab === "update" && <UserUpdate />}
        {activeTab === "user management" && <UserManagement />}
      </div>
    </div>
  );
}

const UserCredentials = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [username, setUsername] = useState(state?.username || "");
  const [password, setPassword] = useState(state?.password || "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Replace with your actual API endpoint
      const response = await apiService.post(
        "/api/users",
        {
          username,
          password,
          firstName,
          lastName,
          email,
          isActive,
          isAdmin,
          isDelete,
        }
      );
      // Handle successful response
      navigate("/dashboard"); // Redirect to Dashboard or another page
    } catch (error) {
      console.error("Failed to post user credentials", error);
      // Handle errors (e.g., show a message to the user)
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded shadow-md w-96"
      >
        <h2 className="text-2xl font-bold mb-4">User Credentials</h2>
        <div className="mb-4">
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700"
          >
            Username
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-gray-700"
          >
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-gray-700"
          >
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="isActive"
            className="block text-sm font-medium text-gray-700"
          >
            Active
          </label>
          <input
            type="checkbox"
            id="isActive"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
            className="mt-1 block"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="isAdmin"
            className="block text-sm font-medium text-gray-700"
          >
            Admin
          </label>
          <input
            type="checkbox"
            id="isAdmin"
            checked={isAdmin}
            onChange={(e) => setIsAdmin(e.target.checked)}
            className="mt-1 block"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="isDelete"
            className="block text-sm font-medium text-gray-700"
          >
            Delete
          </label>
          <input
            type="checkbox"
            id="isDelete"
            checked={isDelete}
            onChange={(e) => setIsDelete(e.target.checked)}
            className="mt-1 block"
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

const UserUpdate = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [id, setId] = useState(state?.id || "");
  const [username, setUsername] = useState(state?.username || "");
  const [firstName, setFirstName] = useState(state?.firstName || "");
  const [lastName, setLastName] = useState(state?.lastName || "");
  const [email, setEmail] = useState(state?.email || "");
  const [isActive, setIsActive] = useState(state?.isActive || true);
  const [isAdmin, setIsAdmin] = useState(state?.isAdmin || false);
  const [isDelete, setIsDelete] = useState(state?.isDelete || false);
  const [createdBy, setCreatedBy] = useState(state?.createdBy || "");
  const [updatedBy, setUpdatedBy] = useState(state?.updatedBy || "");

  useEffect(() => {}, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(
        `/api/users/${id}`,
        {
          username,
          firstName,
          lastName,
          email,
          isActive,
          isAdmin,
          isDelete,
          createdBy,
          updatedBy,
        },
      );
      navigate("/dashboard");
    } catch (error) {
      console.error("Failed to update user", error);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <form
        onSubmit={handleUpdate}
        className="bg-white p-6 rounded shadow-md w-96"
      >
        <h2 className="text-2xl font-bold mb-4">Update User</h2>
        <div className="mb-4">
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700"
          >
            Username
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-gray-700"
          >
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-gray-700"
          >
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="isActive"
            className="block text-sm font-medium text-gray-700"
          >
            Active
          </label>
          <input
            type="checkbox"
            id="isActive"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
            className="mt-1 block"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="isAdmin"
            className="block text-sm font-medium text-gray-700"
          >
            Admin
          </label>
          <input
            type="checkbox"
            id="isAdmin"
            checked={isAdmin}
            onChange={(e) => setIsAdmin(e.target.checked)}
            className="mt-1 block"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="isDelete"
            className="block text-sm font-medium text-gray-700"
          >
            Delete
          </label>
          <input
            type="checkbox"
            id="isDelete"
            checked={isDelete}
            onChange={(e) => setIsDelete(e.target.checked)}
            className="mt-1 block"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="createdBy"
            className="block text-sm font-medium text-gray-700"
          >
            Created By
          </label>
          <input
            type="text"
            id="createdBy"
            value={createdBy}
            onChange={(e) => setCreatedBy(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="updatedBy"
            className="block text-sm font-medium text-gray-700"
          >
            Updated By
          </label>
          <input
            type="text"
            id="updatedBy"
            value={updatedBy}
            onChange={(e) => setUpdatedBy(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded"
        >
          Update
        </button>
      </form>
    </div>
  );
};

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState("");

  // const token = localStorage.getItem("token");

  const handleGetAllUsers = async () => {
    try {
      const response = await apiService.get("/api/users");
      setUsers(response.data);
    } catch (error) {
      console.error("Failed to fetch users", error);
    }
  };

  const handleGetUser = async () => {
    try {
      const response = await apiService.get(`/api/users/${userId}`);
      setUser(response.data);
    } catch (error) {
      console.error("Failed to fetch user", error);
    }
  };

  return (
    <div className="flex flex-col items-center h-screen bg-gray-100 p-6">
      <h2 className="text-2xl font-bold mb-4">User Management</h2>
      <div className="mb-4">
        <button
          onClick={handleGetAllUsers}
          className="py-2 px-4 bg-blue-500 text-white rounded mr-2"
        >
          Get All Users
        </button>
        <input
          type="text"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          placeholder="User ID"
          className="py-2 px-3 border border-gray-300 rounded mr-2"
        />
        <button
          onClick={handleGetUser}
          className="py-2 px-4 bg-green-500 text-white rounded"
        >
          Get User
        </button>
      </div>
      <div className="w-full max-w-3xl">
        {users.length > 0 && (
          <div>
            <h3 className="text-xl font-semibold mb-2">All Users</h3>
            <ul className="list-disc pl-5">
              {users.map((user) => (
                <li key={user.id}>
                  {user.username} - {user.email}
                </li>
              ))}
            </ul>
          </div>
        )}
        {user && (
          <div className="mt-4">
            <h3 className="text-xl font-semibold mb-2">User Details</h3>
            <p>ID: {user.id}</p>
            <p>Username: {user.username}</p>
            <p>First Name: {user.firstName}</p>
            <p>Last Name: {user.lastName}</p>
            <p>Email: {user.email}</p>
            <p>Active: {user.isActive ? "Yes" : "No"}</p>
            <p>Admin: {user.isAdmin ? "Yes" : "No"}</p>
            <p>Delete: {user.isDelete ? "Yes" : "No"}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserPanel;
