import React, { useState, useEffect } from "react";
import {jwtDecode} from "jwt-decode";
import apiService from "../../services/apiService";
import { XCircleIcon } from "@heroicons/react/solid";
import Loader from "../loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const UserUpdate = ({ onClose, user,  onUpdateUser }) => {
  const [id, setId] = useState(user?.ID || "");
  const [username, setUsername] = useState(user?.username || "");
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [email, setEmail] = useState(user?.email || "");
  const [isActive, setIsActive] = useState(user?.isActive || true);
  const [isAdmin, setIsAdmin] = useState(user?.isAdmin || false);
  const [isDelete, setIsDelete] = useState(user?.isDelete || false);
  const [createdBy, setCreatedBy] = useState(null);
  const [updatedBy, setUpdatedBy] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const parseJwt = (token) => {
      try {
        const decoded = jwtDecode(token);
        return parseInt(decoded.id, 10); 
      } catch (e) {
        console.error("Failed to parse JWT token", e);
        return null;
      }
    };

    const token = localStorage.getItem("token");
    const userId = token ? parseJwt(token) : null;

    if (userId) {
      setCreatedBy(userId);
      setUpdatedBy(userId);
    }
  }, [user]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await apiService.put(
        `/api/users/${id}`,
        {
          id,
          username,
          firstName,
          lastName,
          email,
          isActive,
          isAdmin,
          isDelete,
          createdBy,
          updatedBy,
        },
      );
      toast.success("User updated successfully!",{autoClose:2000});
      onClose();

      onUpdateUser({
        id,
        username,
        firstName,
        lastName,
        email,
        isActive,
        isAdmin,
        isDelete,
        createdBy,
        updatedBy,
      });
    } catch (error) {
      console.error("Failed to update user", error);
      toast.error("Failed to update user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <ToastContainer/>
      {loading && <Loader/>}
      <div className="bg-white p-6 rounded shadow-md w-full max-w-md mx-4 sm:mx-auto relative">
        
        <XCircleIcon
          onClick={onClose}
          className="h-6 w-6 text-red-500 hover:text-red-700 absolute top-2 right-2 cursor-pointer"
        />
        <form onSubmit={handleUpdate}>
          <h2 className="text-2xl font-bold mb-4 text-center">Update User</h2>
          <div className="mb-4">
            <label
              htmlFor="id"
              className="block text-sm font-medium text-gray-700"
            >
              ID
            </label>
            <input
              type="text"
              id="id"
              value={id}
              readOnly
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded bg-gray-100"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="isActive"
              className="block text-sm font-medium text-gray-700"
            >
              Active
            </label>
            <input
              type="checkbox"
              id="isActive"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              className="mt-1"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="isAdmin"
              className="block text-sm font-medium text-gray-700"
            >
              Admin
            </label>
            <input
              type="checkbox"
              id="isAdmin"
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
              className="mt-1"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="isDelete"
              className="block text-sm font-medium text-gray-700"
            >
              Delete
            </label>
            <input
              type="checkbox"
              id="isDelete"
              checked={isDelete}
              onChange={(e) => setIsDelete(e.target.checked)}
              className="mt-1"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-orange-500 text-white rounded hover:bg-orange-600"
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserUpdate;
