import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from '../../services/apiService';

const UpdateAgency = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
  
    const [id, setId] = useState(state?.id || "");
    const [agencyName, setAgencyName] = useState(state?.agencyName || "");
    const [agencyDomainName, setAgencyDomainName] = useState(
      state?.agencyDomainName || ""
    );
    const [fkAssignedStore, setFkAssignedStore] = useState(
      state?.fkAssignedStore || 0
    );
    const [fkAgencyStatus, setFkAgencyStatus] = useState(
      state?.fkAgencyStatus || 0
    );
    const [isActive, setIsActive] = useState(state?.isActive || true);
    const [isDelete, setIsDelete] = useState(state?.isDelete || false);
    const [createdBy, setCreatedBy] = useState(state?.createdBy || 0);
    const [updatedBy, setUpdatedBy] = useState(state?.updatedBy || 0);
  
    useEffect(() => {}, [id]);
  
    const handleUpdate = async (e) => {
      e.preventDefault();
      try {
        // const token = localStorage.getItem("token");
        await apiService.put(
          `/api/agencies/${id}`,
          {
            id,
            agencyName,
            agencyDomainName,
            fkAssignedStore,
            fkAgencyStatus,
            isActive,
            isDelete,
            createdBy,
            updatedBy,
          },
        );
        navigate("/dashboard");
      } catch (error) {
        console.error("Failed to update agency", error);
      }
    };
  
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100 p-4">
        <form
          onSubmit={handleUpdate}
          className="bg-white p-6 rounded shadow-md max-w-md w-full"
        >
          <h2 className="text-2xl font-bold mb-4">Update Agency</h2>
          <div className="mb-4">
            <label
              htmlFor="agencyName"
              className="block text-sm font-medium text-gray-700"
            >
              Agency Name
            </label>
            <input
              type="text"
              id="agencyName"
              value={agencyName}
              onChange={(e) => setAgencyName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="agencyDomainName"
              className="block text-sm font-medium text-gray-700"
            >
              Agency Domain Name
            </label>
            <input
              type="text"
              id="agencyDomainName"
              value={agencyDomainName}
              onChange={(e) => setAgencyDomainName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="fkAssignedStore"
              className="block text-sm font-medium text-gray-700"
            >
              Assigned Store
            </label>
            <input
              type="number"
              id="fkAssignedStore"
              value={fkAssignedStore}
              onChange={(e) => setFkAssignedStore(parseInt(e.target.value))}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="fkAgencyStatus"
              className="block text-sm font-medium text-gray-700"
            >
              Agency Status
            </label>
            <input
              type="number"
              id="fkAgencyStatus"
              value={fkAgencyStatus}
              onChange={(e) => setFkAgencyStatus(parseInt(e.target.value))}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              id="isActive"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label
              htmlFor="isActive"
              className="ml-2 text-sm font-medium text-gray-700"
            >
              Active
            </label>
          </div>
          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              id="isDelete"
              checked={isDelete}
              onChange={(e) => setIsDelete(e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label
              htmlFor="isDelete"
              className="ml-2 text-sm font-medium text-gray-700"
            >
              Delete
            </label>
          </div>
          <div className="mb-4">
            <label
              htmlFor="createdBy"
              className="block text-sm font-medium text-gray-700"
            >
              Created By
            </label>
            <input
              type="number"
              id="createdBy"
              value={createdBy}
              onChange={(e) => setCreatedBy(parseInt(e.target.value))}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="updatedBy"
              className="block text-sm font-medium text-gray-700"
            >
              Updated By
            </label>
            <input
              type="number"
              id="updatedBy"
              value={updatedBy}
              onChange={(e) => setUpdatedBy(parseInt(e.target.value))}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Update
          </button>
        </form>
      </div>
    );
  };

export default UpdateAgency