import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loginLogo from "../../assest/img/login-png.png";
import rightSideLogo from "../../assest/img/xeni-logo.png";
import apiService from "../../services/apiService";
import Loader from "../../components/loader/Loader";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await apiService.post("/auth", { username, password });
      const { token , user} = response.data;
      const { isMfaEnabled } = user;
      localStorage.setItem("token", token);
      setSuccess(true);
      toast.success("Login successful! Redirecting...", {autoClose: 1000});
      
      setTimeout(() => {
        if (isMfaEnabled) {
         navigate('/verify-otp',{ state: { username } });
        } else {
         navigate('/register-totp',{state:{username}});
        }
        
      }, 2000);

    } catch (error) {
      console.error("Login error:", error);

      const errorMessage = error.response?.data?.message || "An unexpected error occurred. Please try again.";
      toast.error(errorMessage, { autoClose: 5000 }); 
  } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row relative">
      <ToastContainer />
      {loading && <Loader />}  
      <div className="flex flex-col md:flex-row w-full">
      <div className="flex items-center justify-center w-full md:w-1/2 bg-gray-100 mt-6 md:mt-0">
          <img src={rightSideLogo} alt="Right Side Logo" className="h-80 sm:h-48" />
        </div>
        <div className="flex items-center justify-center w-full md:w-1/2 bg-gray-100">
          <div className="p-8 rounded-lg shadow-lg max-w-md w-full transform transition duration-500 hover:scale-105">
            <div className="mb-6 text-center">
              <img src={loginLogo} alt="Logo" className="mx-auto h-16 w-16" />
            </div>
            <h2 className="text-2xl font-bold mb-6 text-center" style={{ color: "#225282" }}>Login</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full p-3 border border-orange-500 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
              </div>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-3 border border-orange-500 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <FaEyeSlash className="text-gray-400" />
                  ) : (
                    <FaEye className="text-gray-400" />
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="w-full bg-orange-500 text-white p-3 rounded hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50 transition-colors"
                disabled={loading}
              >
                {loading ? "Logging in..." : "Login"}
              </button>
            </form>
            <div className="mt-4 text-center">
              <p className="text-gray-600 mt-6">
              Forgot your password?{" "}
                <Link
                  to="/forgot-password"
                  className="text-orange-500 hover:underline focus:outline-none focus:ring-2 focus:ring-orange-500"
                >
                  Forgot Password?
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
