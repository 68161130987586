import React, { useState } from "react";
import apiService from "../../services/apiService";

const UpdateStore = ({ onClose, store }) => {
  const [storeName, setStoreName] = useState(store.storeName);
  const [currentAcmID, setCurrentAcmID] = useState(store.currentAcmID || "");
  const [fkStoreStatus, setFkStoreStatus] = useState(store.fkStoreStatus || 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No authentication token found");
        return;
      }

      await apiService.put(
        `/api/stores/${store.ID}`,
        {
          ID: store.ID,
          storeName,
          currentAcmID,
          fkStoreStatus,
        },
      );

      onClose();
    } catch (error) {
      console.error("Failed to update store", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4">Update Store</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="storeName"
              className="block text-sm font-medium text-gray-700"
            >
              Store Name
            </label>
            <input
              type="text"
              id="storeName"
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="currentAcmID"
              className="block text-sm font-medium text-gray-700"
            >
              Current ACM ID
            </label>
            <input
              type="text"
              id="currentAcmID"
              value={currentAcmID}
              onChange={(e) => setCurrentAcmID(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="fkStoreStatus"
              className="block text-sm font-medium text-gray-700"
            >
              Store Status
            </label>
            <input
              type="number"
              id="fkStoreStatus"
              value={fkStoreStatus}
              onChange={(e) => setFkStoreStatus(parseInt(e.target.value))}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600"
          >
            Update
          </button>
          <button
          onClick={onClose}
          className="w-full bg-gray-400 py-2 px-4 rounded hover:bg-red-500 mt-2"
        >
          Close
        </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateStore;
