import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import imageLogo from "../assest/img/xeni-logo.png"; 

function VerifyOTP() {
  const location = useLocation();
  const [code, setCode] = useState(""); 
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { username } = location.state || {}; // Retrieve username from state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL+"verify-totp"}`,
        { code, UserName: username },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/admin-panel");
    } catch (error) {
      setError(error.response?.data?.message || "An error occurred");
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row relative">
      <div className="flex items-center justify-center w-full md:w-1/2 bg-gray-100 mt-6 md:mt-0">
        <img src={imageLogo} alt="Logo" className="w-2/3 sm:w-1/2" />
      </div>
      <div className="flex items-center justify-center w-full md:w-1/2 bg-gray-100">
      <div
        className="p-20 sm:p-8 rounded-lg shadow-lg border border-orange-300 w-full max-w-xs sm:max-w-sm md:max-w-sm bg-white sm:ml-8"
      >
        <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center" style={{color:"#225282"}}>Verify OTP</h2>
        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="text"
              placeholder="Enter OTP"
              value={code} 
              onChange={(e) => setCode(e.target.value)} // Use setCode
              className="w-full p-3 border border-orange-300 rounded focus:outline-none focus:border-orange-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-orange-600 text-white p-3 rounded hover:bg-orange-500 transition-colors"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Verifying..." : "Verify OTP"}
          </button>
        </form>
      </div>
      </div>
    </div>
  );
}

export default VerifyOTP;



