import axios from "axios";

const apiService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000

});
apiService.interceptors.request.use((config) => {

    const token = localStorage.getItem("token");
    const request = localStorage.getItem("requestid");
    console.log(request);
    if(request){

        config.headers["X-Request-Id"] = request
    }else{
        const uuid = require('uuid').v4();
        config.headers["X-Request-Id"] = uuid;
        localStorage.setItem("requestid", uuid);
    }

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    // config.headers["X-Request-Id"] = request;


    return config
},(error) => {

    return Promise.reject(error)
});

apiService.interceptors.response.use((response) => {
    console.log("This is respnse",response.status);
    if (response.status === 401) {
        console.log("This is respnse",response.status);
        window.location.navigate('/');
    }
    // Validate UUID
    if (response.headers["x-request-id"]) {
        if(response.headers["x-request-id"] !== localStorage.getItem("requestid")){
            // localStorage.setItem("requestid", response.headers["x-request-id"]);
            window.location.navigate("/");
        }
    }

    return response
}, (error) => {
    return Promise.reject(error)
});
export default apiService